import * as React from 'react'

// IMPORTANT -- this component should be kept as lightweight as possible, as it is rendered pre-loading/suspense states
// Avoid adding packages wherever possible, and copy code rather than importing it where possible

// Unfortunately that doesn't include this custom CSS file, which we have to manually import here due to a bug
// with Banquet's CSS preprocessing. TODO -- once that bug's fixed, should be able to just manually import in the original file location
import '../../packages/app-tax-center/alert/components/styles.css'

const TaxCenter = React.lazy(
  () =>
    import(
      /*webpackChunkName: "taxcenter.application" */
      '../../packages/app-tax-center/'
    )
)

const App = () => (
  // Showing `null` now rather than the full loader experience
  // If future metrics show customers run into issues loading the bundle for the tax center,
  // can investigate showing more sophisticated loading states (vs cost of making this bundle itself more expensive to load)
  <React.Suspense fallback={null}>
    <TaxCenter />
  </React.Suspense>
)

export { App }
