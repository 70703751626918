import { banquetSingleSpaReact, BanquetProps } from 'banquet-runtime-modules'
import { App } from './app/App'
import { ThemeManager } from '@toasttab/banquet-theme-manager'
import { themes } from '@local/themes'
import './index.css'

const reactLifecycles = banquetSingleSpaReact({
  cssScope: 'data-payroll-tax-center',
  rootComponent: App,
  portalContainers: ['banquetPortalsContainer'],

  sentry: {
    publicKey: '90daaedeeaf491e235869982c3ac8a37@o37442',
    projectId: '4506785394130944',
    releaseVersion: process.env.SPA_NAMED_VERSION || process.env.PKG_VERSION
  },

  theme: new ThemeManager((_props: BanquetProps) => {
    return themes.next
  })
})

export const bootstrap = reactLifecycles.bootstrap
export const mount = reactLifecycles.mount
export const unmount = reactLifecycles.unmount
export const name = 'payroll-tax-center-spa'
